import * as Sentry from "@sentry/nuxt";

if (!import.meta.dev) {
  Sentry.init({
    dsn: useRuntimeConfig().public.sentry.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["https://newfront.genotic.com"],
  });
}
